import ctl from "@netlify/classnames-template-literals";

export const header = ctl(`
  z-10
  flex
  items-center
  justify-between
  bg-white
  p-8
  md:hidden
`);

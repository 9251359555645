import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  bg-purple-100
  flex
  flex-nowrap
  items-center
  justify-between
  border-gray-200
  p-2
  pl-8
  w-full
  z-20
`);

export const lockWrapper = ctl(`
  inline-block
  self-center
  text-center
`);

import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { IMPERSONATION_LOCAL_STORAGE_KEY } from "@olivahealth/constants";
import { Button, UserIcon } from "@olivahealth/oli-ui";
import { signInWithCustomToken } from "@firebase/auth";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { useAuth } from "@olivahealth/firebase/client";

import { useUserData } from "../../../services/contexts/UserDataContext";
import { useSignOut } from "../../../services/contexts/SignOutContext";

import * as s from "./styles";

interface ImpersonatorData {
  impersonatorToken: string;
  impersonatorId: string;
}

export default function ImpersonatorBanner(): JSX.Element | null {
  const auth = useAuth();
  const { signOut } = useSignOut();
  const router = useRouter();
  const { data: user } = useUserData();

  const [impersonatorData, setImpersonatorData] =
    useState<ImpersonatorData | null>(null);
  const shouldSeeBanner = impersonatorData !== null;

  useEffect(() => {
    const storage = localStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY);
    if (!storage) {
      setImpersonatorData(null);
      return;
    }
    const { impersonatorId, impersonatorToken } = JSON.parse(storage);
    if (impersonatorId) {
      setImpersonatorData({ impersonatorToken, impersonatorId });
    }
  }, []);

  const doFinishImpersonation = async () => {
    if (!impersonatorData) {
      return;
    }
    try {
      await signInWithCustomToken(auth, impersonatorData.impersonatorToken);
      localStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY);
      router.push("/admin/impersonate");
    } catch (error) {
      localStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY);
      signOut("Failed to finish impersonation");
      router.push("/");

      console.error("Failed to finish impersonation", error);
    }
  };

  if (!shouldSeeBanner) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="hidden pr-8 sm:block">
        <span className={s.lockWrapper}>
          <UserIcon
            variant="block"
            size={35}
            color={tokens.colors["purple"][500]}
          />
        </span>
      </div>
      <div className="grow pr-8">
        <p>
          You are impersonating{" "}
          <strong>
            {user?.name} {user?.surname}
          </strong>
        </p>
      </div>
      <div>
        <Button
          data-testid="enable-mfa"
          variant="primary"
          size="sm"
          onClick={doFinishImpersonation}
        >
          Finish impersonation
        </Button>
      </div>
    </div>
  );
}

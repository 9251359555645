import { Logo } from "@olivahealth/oli-ui";
import * as s from "../Sidebar/styles";

export default function SidebarPlaceholder(): JSX.Element {
  return (
    <aside className={s.sidebar} data-testid="sidemenu">
      <div>
        <header>
          <div className="mb-8 flex items-center p-2.5">
            <Logo height={28} color="light" />
          </div>
        </header>
      </div>
    </aside>
  );
}

import { useRouter } from "next/router";
import { Logo } from "@olivahealth/oli-ui";
import Navbar from "../Navbar";
import * as s from "./styles";

export default function Sidebar() {
  const { push } = useRouter();

  return (
    <div className={s.sidebar} data-testid="sidemenu">
      <div className="mb-8 flex items-center p-2.5">
        <button aria-label="return to home" onClick={() => push("/")}>
          <Logo height={28} color="light" />
        </button>
      </div>
      <div className="w-full">
        <Navbar />
      </div>
    </div>
  );
}

import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useRouter } from "next/router";
import { ROLES_REQUIRED_MFA } from "@olivahealth/constants";
import { Button, LockIcon } from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { FeatureFlagsKeys } from "@olivahealth/utils/types/FeatureFlags";

import useTranslation from "../../../hooks/useTranslation";
import { useUserData } from "../../../services/contexts/UserDataContext";
import * as s from "./styles";

export default function MfaBanner(): JSX.Element | null {
  const mfaProfessionalEnabled = useFeatureIsOn(
    FeatureFlagsKeys.MFA_PROFESSIONAL as string,
  );
  const { t } = useTranslation("auth", { keyPrefix: "multiFactorBanner" });
  const { route, push } = useRouter();
  const { data: user } = useUserData();

  const isMfaRoute = route === "/profile/mfa";

  const roleEligibleToSeeBanner = user?.role?.some((item) =>
    ROLES_REQUIRED_MFA.includes(item),
  );

  const hideBanner =
    !mfaProfessionalEnabled ||
    isMfaRoute ||
    !user ||
    user.hasEnrolledMfa ||
    !roleEligibleToSeeBanner;

  if (hideBanner) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="hidden pr-8 sm:block">
        <span className={s.lockWrapper}>
          <LockIcon size={35} color={tokens.colors["purple"][500]} />
        </span>
      </div>
      <div className="grow pr-8">
        <p>
          <strong>{t("title")}</strong>
          <br />
          {t("message")}
        </p>
      </div>
      <div>
        <Button
          data-testid="enable-mfa"
          variant="primary"
          size="sm"
          onClick={() => {
            push("/profile/mfa");
          }}
        >
          {t("activate")}
        </Button>
      </div>
    </div>
  );
}

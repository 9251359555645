/**
 * @deprecated DO NOT USE THIS TYPE. Prefer FeatureFlagsKeys and FeatureFlagsValues instead.
 * Feature flags are used to enable/disable features in the app
 */
export interface FeatureFlags {
  feature_permissions: FeaturePermissions;
  unifiedProgressCheckSurvey: boolean;
  profileInsuranceTab: boolean;
}

export enum FeatureFlagsKeys {
  MFA_PROFESSIONAL = "mfa_professional",
  WORKSHOP_ONLY = "workshop_only",
  SIMULTANEOUS_THERAPY_COACHING = "simultaneous_therapy_coaching_web",
}

export interface FeatureFlagsValues {
  [FeatureFlagsKeys.MFA_PROFESSIONAL]: boolean;
  [FeatureFlagsKeys.WORKSHOP_ONLY]: boolean;
  [FeatureFlagsKeys.SIMULTANEOUS_THERAPY_COACHING]: boolean;
}

/**
 * Feature permissions are used to enable/disable features for specific users
 */
export interface FeaturePermissions {
  chat_as_care_navigator: Array<string>;
  chat_as_clinical_supervisor: Array<string>;
  chat_as_oliva_customer_support: Array<string>;
  admin_tools: Array<string>;
}

import { Navbar as OliNavbar } from "@olivahealth/oli-ui";
import useTranslation from "../../../hooks/useTranslation";
import { useUserData } from "../../../services/contexts/UserDataContext";
import useNavbar from "./useNavbar";

export default function Navbar(): JSX.Element | null {
  const { data: userData } = useUserData();
  const {
    data: { isOrganisationAdmin, links },
  } = useNavbar();
  const { t } = useTranslation("common", { keyPrefix: "navbar" });

  if (!links || links.length === 0) {
    return null;
  }

  return (
    <OliNavbar
      isOrganisationAdmin={isOrganisationAdmin}
      hasTeamSessionsOnly={userData?.organisation?.hasTeamSessionsOnly ?? false}
      labels={{
        admin: t("labels.admin"),
        careJourney: t("labels.careJourney"),
        company: t("labels.company"),
      }}
      links={links}
    />
  );
}

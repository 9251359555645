import { Suspense } from "react";
import cx from "classnames";
import SidebarPlaceholder from "../../../rebrand/organisms/SidebarPlaceholder";
import MfaBanner from "../../../rebrand/organisms/MfaBanner";
import ImpersonatorBanner from "../../../rebrand/organisms/ImpersonatorBanner";
import Header from "../../../rebrand/organisms/Header";
import Sidebar from "../../../rebrand/organisms/Sidebar";
import * as s from "./styles";

interface IProps {
  showLogo?: boolean;
  showSidebar?: boolean;
  showNavBar?: boolean;
  children: React.ReactNode;
}

/**
 * This component provides a base layout to be shared across all pages
 */
export default function AppLayout({
  showLogo = true,
  showSidebar = true,
  showNavBar = true,
  children,
}: IProps): JSX.Element {
  return (
    <>
      <Suspense fallback={null}>
        <Header showLogo={showLogo} showNavBar={showNavBar} />
      </Suspense>
      {showSidebar && (
        <Suspense fallback={<SidebarPlaceholder />}>
          <Sidebar />
        </Suspense>
      )}
      <section
        className={cx("h-auto", {
          "md:ml-64": showSidebar,
          "md:w-full": !showSidebar,
        })}
      >
        <MfaBanner />
        <ImpersonatorBanner />
      </section>
      <main
        className={cx(s.mainContent, {
          "md:ml-64": showSidebar,
          "md:w-full": !showSidebar,
        })}
      >
        {children}
      </main>
    </>
  );
}

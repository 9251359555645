import { Logo } from "@olivahealth/oli-ui";
import { useRouter } from "next/router";
import Navbar from "../Navbar";
import * as s from "./styles";

export default function Header({
  showLogo = true,
  showNavBar = true,
}: {
  showLogo?: boolean;
  showNavBar?: boolean;
}): JSX.Element | null {
  const { push } = useRouter();

  if (!showLogo && !showNavBar) {
    return null;
  }

  return (
    <header className={s.header}>
      {showLogo && (
        <div className="flex items-center">
          <button aria-label="return to home" onClick={() => push("/")}>
            <Logo height={28} color="light" />
          </button>
        </div>
      )}
      {showNavBar && <Navbar />}
    </header>
  );
}
